@import '../../assets/styles/common/variables.scss';

.cards {
  margin: 50px 20px 0;
  position: relative;
  min-height: 350px;

  @media (min-width: 576px) {
    max-width: 550px;
    min-height: 450px;
    margin: 80px auto 0;
  }
}

.cards-counter {
  @media (min-width: 576px) {
    display: none;
  }
}

.card {
  position: absolute;
  width: 100%;
  background-color: $white;
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
  will-change: transform;
  transition: all 0.3s ease-in-out;
  cursor: grab;

  &:not(:nth-child(1)) {
    background-color: $black;
    border: 2px solid $white;
  }

  &:nth-child(1) {
    background-color: $white;
    border: 2px solid $white;
  }

  &.is-active {
    background-color: $white;
    border: 2px solid $white;
  }

  &.moving {
    transition: none;
    cursor: grabbing;
  }

  &.removed {
    display: none;
  }

  &__image {
    position: relative;
    border-radius: 10px;
    height: 185px;
    background-size: cover;
    pointer-events: none;

    @media (min-width: 576px) {
      height: 250px;
    }
  }

  &__title {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 20px 0 10px;
    text-align: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    pointer-events: none;
  }

  &__description {
    pointer-events: none;
  }

  &__actions {
    gap: 20px;
  }
}
