* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  font-family: $font-family-base;
  color: $black;
  background-color: $white;
  overflow-x: hidden;
}

ul.list {
  list-style-type: none;

  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &::before {
      content: '';
      display: inline-block;
      background-image: url('../../images/heart-green.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
  }
}

.page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  width: 100%;
  height: 100vh;
  background-color: $black;
  opacity: 0.4;
  user-select: none;

  @media (min-width: 991px) {
    display: none;
  }
}

.container {
  max-width: 560px;
  margin: 0 auto;
}
