h1 {
  font-family: $font-family-montserrat;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

h2 {
  font-family: $font-family-montserrat;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
}

h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

p {
  font-size: 16px;
  line-height: 20px;
}

small {
  font-size: 12px;
  line-height: 14px;

  strong {
    font-family: $font-family-montserrat;
    font-size: 10px;
    line-height: 12px;
    font-weight: 900;
  }
}
