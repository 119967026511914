.form-group {
  margin-bottom: 15px;
}

label {
  font-family: $font-family-montserrat;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 5px;
  display: block;
}

.form-control {
  display: block;
  border: 2px solid $black;
  border-radius: 10px;
  font-size: 16px;
  line-height: 14px;
  padding: 15px;
  width: 100%;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}
