.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.flex {
  &-wrap {
    flex-wrap: wrap;
  }

  &-column {
    flex-direction: column;
  }
}

.align-items {
  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }
}

.justify-content {
  &-start {
    justify-content: flex-start;
  }

  &-end {
    justify-content: flex-end;
  }

  &-between {
    justify-content: space-between;
  }

  &-center {
    justify-content: center;
  }
}

.text {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}

.color {
  &-white {
    color: $white;
  }
}
