$white: #fff;
$black: #000;
$red: #ff6060;
$green: #33ffda;

$primary: $green;
$danger: $red;

$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;

$font-family-base: $font-family-roboto;

$spacers: (
  '5px': 5px,
  '10px': 10px,
  '15px': 15px,
  '20px': 20px,
  '30px': 30px,
  '35px': 35px,
  '40px': 40px,
  '50px': 50px,
  '60px': 60px,
  '65px': 65px,
  '70px': 70px,
  '80px': 80px,
  '85px': 85px,
  '90px': 90px,
  '100px': 100px
);
