.home-page-background {
  min-height: 375px;
  background-image: url('../../images/bg.svg'), url('../../images/ufo.svg'), url('../../images/planet.svg');
  background-size: cover, 260px 295px, 58px 45px;
  background-repeat: no-repeat;
  background-position: center top, center 40px, right 35px top 0;

  @media (max-width: 450px) {
    background-position: center 110px, center 40px, right 35px top 0;
  }

  @media (min-width: 576px) {
    background-image: url('../../images/bg-desktop.svg'), url('../../images/ufo.svg'), url('../../images/planet.svg');
  }

  @media (min-width: 1200px) {
    min-height: 500px;
  }
}

.home-page-content {
  width: 100%;
  background-color: $black;
  padding: 20px 0 95px;

  @media (min-width: 576px) {
    padding: 85px 0 110px;
  }

  h1 {
    @media (min-width: 991px) {
      max-width: 990px;
      margin: 0 auto;
      font-size: 72px;
      line-height: 88px;
    }
  }
}
