@import '../../assets/styles/common/variables.scss';

.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 30px 20px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: $white;
}
