.btn {
  font-family: $font-family-montserrat;
  font-size: 12px;
  line-height: 15px;
  font-weight: 900;
  background-color: $white;
  display: inline-block;
  color: $black;
  border: 2px solid $black;
  box-shadow: 3px 3px 0px $black;
  border-radius: 10px;
  padding: 13px 15px;
  text-transform: uppercase;
  text-align: center;
  transition: all 200ms ease-in-out;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &.btn-small {
    font-size: 10px;
    line-height: 12px;
  }
}

.btn,
.btn-icon {
  &:active,
  &:hover {
    box-shadow: none;
    transform: translate(3px, 3px);
  }

  &.btn-primary {
    background-color: $primary;
  }

  &.btn-danger {
    background-color: $danger;
  }
}

.btn-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  background-color: $white;
  box-shadow: 3px 3px 0px $black;
  transition: all 200ms ease-in-out;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border: 2px solid $black;

  &:active,
  &:hover {
    box-shadow: none;
    transform: translate(3px, 3px);
  }
}

.btn-text {
  font-family: $font-family-montserrat;
  font-size: 10px;
  line-height: 12px;
  font-weight: 900;
  color: $black;
  text-decoration: underline;
  text-transform: uppercase;
  border: 0;
  background: transparent;
}
