@import '../../assets/styles/common/variables.scss';

.apply-form {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: $white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  top: calc(100% - 54px);
  transition: top 200ms ease-in-out;

  @media (min-width: 576px) {
    right: 30px;
    left: auto;
    width: 400px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }

  &.is-open {
    top: 104px;
    bottom: 0;

    .apply-form__trigger {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__trigger {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background-color: $white;
    border-radius: 50%;
    text-align: center;
    z-index: -1;

    img {
      margin-top: 8px;
    }
  }

  &__header {
    padding: 18px 0;
    cursor: pointer;
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/images/heart-shadow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $font-family-montserrat;
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }

  &__content {
    padding: 10px 20px 50px;

    @media (max-width: 576px) {
      overflow-y: scroll;
      max-height: 530px;
    }
  }
}
